import { getTenantName, isCorpTenant } from "@capone/common";
import { CurrencyFormatters, getTravelCreditAndOfferTitle } from "halifax";
import React from "react";
import { Tenant } from "redmond";
import { WallStreetAmount } from "redmond/apis/tysons/wallet";

export const CONTACT_SUPPORT_HEADER = "Contact support";
export const CONTACT_SUPPORT_TEXT =
  "For any questions regarding your trip, please contact our support team and we'd be happy to help.";
export const CONTACT_SUPPORT_NUMBER = "844-422-6922";

export const LOADING_OFFERS = "Fetching offers";
export const LOADING_WALLET = "Loading travel credits and offers";

export const HEADER_TITLE_OFFERS = (name: string) => (
  <>
    Welcome to your <strong>travel offers</strong>, {name}
  </>
);

export const HEADER_TITLE_WALLET = (name: string) => (
  <>
    Welcome to your <strong>Travel credits and Offers</strong>, {name}
  </>
);

export const REDESIGN_HEADER_TITLE_WALLET = (includeOffers: boolean) =>
  getTravelCreditAndOfferTitle(includeOffers);

export const REDESIGN_HEADER_TITLE_OFFER = "My travel offers";

export const HEADER_SUBTITLE_WALLET = (includeOffers: boolean) =>
  `Learn more about your available travel credits${
    includeOffers ? " and travel offers." : ""
  }`;

export const HEADER_SUBTITLE_OFFERS =
  "Learn more about your available travel offers.";

export const FAQs = (tenant: Tenant, showOffers: boolean) => {
  const isCorporate = isCorpTenant(tenant);
  const capOneName = getTenantName(tenant);

  const offersFAQs = [
    {
      title: "What are travel offers?",
      body: `Travel offers are limited-time deals for specific trips (e.g., $50 off flights to Honolulu). Travel offers tend to have an expiration period, sometimes expiring in as little as a day, and other times available while supplies last.`,
    },
    {
      title: "How do travel offers work?",
      body: `Travel offers can be applied to flight, hotel and car rental bookings made through ${capOneName}. Each travel offer has a specific applicability, so it won’t work for all trip types. Travel offers are automatically applied to your booking, but you have the option to defer travel offers to future bookings.`,
    },
    {
      title: "Do travel offers expire?",
      body: `Individual travel offers have their own expiration dates. You can find the expiration date and other terms for each travel offer directly under the travel offer amount in your profile.`,
    },
    {
      title: "How much of a Travel Offer can I redeem towards a purchase?",
      body: `Travel offers are applicable for one-time use only. If your travel offer exceeds the total of your booking, you won’t be able to use the difference on a separate transaction.`,
    },
    {
      title: "Are travel offers refundable?",
      body: `Travel offers are non-refundable.`,
    },
  ];
  return [
    {
      title: "What are travel credits?",
      body: CREDIT_DETAILS_DESCRIPTION(tenant),
    },
    {
      title: `How do I redeem a travel credit on ${capOneName}?`,
      body: `If you have travel credits from ${capOneName}, you’ll have the option to apply them toward the cost of your booking at checkout. You can see your total travel credit balance at any time by navigating to “${getTravelCreditAndOfferTitle(
        showOffers
      )}.”`,
    },
    {
      title: "Do travel credits expire?",
      body: `Some travel credits issued through ${capOneName} don’t have an expiration date, such as those issued in connection with the terms and conditions of certain free or purchased products. ${
        isCorporate
          ? "If an expiration date applies, it will be indicated in the travel wallet."
          : "Annual Travel Credits issued for Venture X and Venture X Business primary account holders will expire on the next account open date anniversary."
      }`,
    },
    {
      title: "Can I use a travel credit on more than one booking?",
      body: `Yes, depending on the amount of your travel credit, you may be able to use it on multiple bookings. For example, if your travel credit is worth more than the total cost of a trip you’re booking, you will keep any remaining travel credit balance to use later. Alternatively, if your travel credit is worth less than the total cost of your booking, you’ll need to use your eligible Capital One credit card or Capital One rewards to pay the remaining balance.`,
    },
    {
      title:
        "If I get a refund for my booking, what happens to the travel credit I applied?",
      body: `If you receive a refund for a booking made through ${capOneName}, it will be issued to the original payment method(s). So, if you used a travel credit to pay for some or all of your booking, you’ll receive that same amount back in travel credit for future use.${
        !isCorporate
          ? " If a purchase made using an Annual Travel Credit is canceled after the expiration date of the credit, the credit will not be restored."
          : ""
      }`,
    },
    ...(showOffers
      ? offersFAQs
      : [
          {
            title: "Where are offers?",
            body: "Capital One Travel for Business is focused on building out features that are relevant to business travel. We are exploring business travel-specific offers, and plan to include those in your travel wallet in the future. If you would like to see non-business travel offers on Capital One Travel for Business as well, please let us know by submitting feedback via the survey horn in the corner.",
          },
        ]),
  ];
};

export const SUPPORT_TEXT_OFFERS = (
  <>
    <strong>Still have questions about travel offers?</strong> Contact our
    customer support team for further assistance.
  </>
);
export const SUPPORT_TEXT_WALLET = (includeOffers: boolean) => (
  <>
    <p style={{ margin: 0 }}>
      <strong>{`Still have questions about your travel credits${
        includeOffers ? " and offers" : ""
      }?`}</strong>
    </p>
    Contact our customer support team for further assistance.
  </>
);

export const SUPPORT_URL =
  "https://www.capitalone.com/help-center/capital-one-travel/";

export const CREDIT_DETAILS_TITLE = (
  <>
    <strong>Travel credits</strong>
  </>
);
export const CREDIT_DETAILS_DESCRIPTION = (tenant: Tenant) =>
  `Travel credits are a form of payment issued by Capital One that you may apply to future purchases on Capital One Travel${
    isCorpTenant(tenant)
      ? " for Business and, if you have separate Capital One card accounts with access to our standard travel website, Capital One Travel as well. Travel credits may be issued in specific scenarios, such as when you receive our price match guarantee."
      : "."
  }`;

export const CREDIT_BALANCE_DESCRIPTION = (name: string) =>
  `${name}'s travel credits`;
export const CREDIT_BALANCE_TEXT = (amount?: WallStreetAmount) => (
  <>
    <strong>
      {CurrencyFormatters.get(amount?.currency).format(
        (amount?.amount && amount?.amount * -1) || 0
      )}
    </strong>{" "}
    available to spend on travel
  </>
);
export const CREDIT_BALANCE_APPLICABILITY_TEXT = "Valid on all bookings";

export const READ_TERMS_AND_CONDITIONS = "Read terms and conditions";

export const VIEW_CREDIT_HISTORY_DESCRIPTION =
  "View your travel credit activity";
export const VIEW_ACTIVITY_CTA = "View activity";
export const CREDIT_HISTORY_MODAL_SUBHEADER = "Available to spend on travel";
export const CREDIT_HISTORY_MODAL_LIST_HEADER = "Travel credit activity:";

export const CREDIT_HISTORY_BALANCE_TEXT = (
  currencySymbol: string,
  amount: string
) => {
  const [dollars, cents] = amount.split(".");
  return (
    <>
      <span className="balance-amount-currency-symbol">{currencySymbol}</span>
      {/* since the currency symbol is in it's own span for styling purposes, it is removed from the dollars string */}
      {dollars.replace(currencySymbol, "")}
      <span className="balance-amount-cents">{cents}</span>
    </>
  );
};

export const TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE =
  "Save up to $200 on select hotels with our first-ever travel sale.";
export const TRAVEL_SALES_EVENT_ACTIVE_CTA = "Explore destinations on sale";

export const TRAVEL_SALES_EVENT_LEADUP_SUBTITLE =
  "Our first-ever travel sale lands in";
export const TRAVEL_SALES_EVENT_LEADUP_CTA = "Explore upcoming sale";

export const HSP_OFFER_TITLE_LABEL =
  "Congrats, you have two hotel offers to choose from:";
