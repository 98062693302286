import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { TravelSaleLandingConnectorProps } from "./container";
import "./styles.scss";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  Typography,
} from "@material-ui/core";
import { useDeviceTypes } from "../../hooks/useDeviceTypes";
import clsx from "clsx";
import queryStringParser from "query-string";
import {
  AccordionCollection,
  Icon,
  PageTabNavigations,
  SecondaryHeader,
  TravelOfferSaleCard,
  TravelSalesEventBanner,
  TravelSaleCardCollection,
  sortTravelSaleDestinations,
  GenericDropdown,
  IconName,
  transformToStringifiedHotelAvailabilityQuery,
  B2BSpinner,
  ButtonWrap,
  isOfferSoldOut,
} from "halifax";
import * as textConstants from "./textConstants";
import {
  BASE_PATH_HOME,
  PATH_CARS,
  PATH_CARS_SEARCH,
  PATH_FLIGHTS,
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS,
  PATH_HOTEL_AVAILABILITY,
  PATH_PREMIUM_STAYS,
} from "../../utils/paths";
import { useInView } from "react-intersection-observer";
import {
  CLICKED_SALE_OFFER_CTA,
  CallState,
  ClickedSaleOfferCTATrackingProperties,
  CarEntryTypeEnum,
  FlightEntryTypeEnum,
  Funnel,
  HotelEntryTypeEnum,
  SALE_FILTER_APPLIED,
  SaleFilterAppliedTrackingProperties,
  TravelWalletOffer,
  VIEWED_SALE_OFFER_MODAL,
  VIEWED_SALE_PAGE,
  ViewedSaleOfferModalTrackingProperties,
  ViewedSalePageTrackingProperties,
} from "redmond";
import {
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_LEAD_UP,
  TRAVEL_SALE_VARIANTS,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../context/experiments";
import { config } from "../../api/config";
import { TravelSaleSearchControl } from "./components/TravelSaleSearchControl";
import { MobileTravelSaleSearchControl } from "./components/MobileTravelSaleSearchControl";
import { trackEvent } from "../../api/v1/analytics/trackEvent";

export interface ITravelSaleLandingProps
  extends TravelSaleLandingConnectorProps,
    RouteComponentProps {}

interface ApplicableOffers {
  offer: TravelWalletOffer;
  onClickCTA: () => void;
}

interface GroupedOffer {
  image: string;
  applicableOffers: ApplicableOffers[];
}

const sortDestinations = sortTravelSaleDestinations(
  window.__mclean_env__.TRAVEL_SALE_SORTED_DESTINATIONS
);

const getFunnelTrackingName = (funnel: Funnel) => {
  switch (funnel) {
    case Funnel.Air:
      return "air";
    case Funnel.Lodging:
      return "hotels";
    case Funnel.Ground:
      return "cars";
  }
};

export const TravelSaleLanding = ({
  filteredTravelOffers,
  offersByFunnel,
  fetchTravelWalletDetails,
  selectedFilter,
  history,
  selectFilter,
  clearSelectedFilters,
  searchLocation,
  fetchTravelWalletDetailsCallState,
  selectedRewardsAccount,
  fetchRewardsAccounts,
}: ITravelSaleLandingProps) => {
  const { matchesDesktop, matchesMobile } = useDeviceTypes();

  const [clickedSectionId, setClickedSectionId] = useState<string>();
  const [showToTopButton, setVisible] = useState(false);

  const [featureDealRef, featureDealInView] = useInView();
  const [hotelDealsRef, hotelDealsInView] = useInView();
  const [flightDealsRef, flightDealsInView] = useInView();
  const [carDealsRef, carDealsInView] = useInView({ threshold: 0.5 });
  const [faqRef, faqInView] = useInView();

  const expState = useExperiments();

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const isActiveState = travelSaleVariant == TRAVEL_SALE_ACTIVE;

  const hasFilters = !!searchLocation || !!selectedFilter;

  useEffect(() => {
    fetchRewardsAccounts();
  }, []);

  useEffect(() => {
    const { entryType } = queryStringParser.parse(history.location.search);
    trackEvent({
      eventName: VIEWED_SALE_PAGE,
      properties: {
        entry_type: entryType ? (entryType as string) : undefined,
      } as ViewedSalePageTrackingProperties,
    });
  }, []);

  useEffect(() => {
    if (selectedFilter) {
      const filterName = (() => {
        switch (selectedFilter) {
          case "travelSaleInternationalDestinations":
            return "international";
          case "travelSaleUSDestinations":
            return "us";
          case "travelSaleBeachDestinations":
            return "beach";
          case "travelSaleWinterDestinations":
            return "winter";
          default:
            return selectedFilter;
        }
      })();
      trackEvent({
        eventName: SALE_FILTER_APPLIED,
        properties: {
          filter_type: filterName,
        } as SaleFilterAppliedTrackingProperties,
      });
    }
  }, [selectedFilter]);

  const filterLabel = () => {
    if (selectedFilter) {
      return (
        textConstants.FILTER_NAMES[selectedFilter].split(" ")[1] +
        " " +
        textConstants.FILTER_NAMES[selectedFilter].split(" ")[2]
      );
    }
    return "";
  };

  const scrollToSection = (sectionId: string) => {
    if (!matchesMobile) {
      const element = document.getElementById(sectionId);

      const BANNER_HEIGHT =
        document
          .querySelector(".b2b-portal-banner-root")
          ?.getBoundingClientRect().height ?? 0;
      const HEADER_HEIGHT =
        document.querySelector(".app-header")?.getBoundingClientRect().height ??
        0;
      const MARGIN = 24;
      const NAV_HEIGHT =
        document.querySelector(".page-nav")?.getBoundingClientRect().height ??
        0;

      const yOffset =
        window.pageYOffset -
        (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN + NAV_HEIGHT);

      const y = (element?.getBoundingClientRect().top ?? 0) + yOffset;

      setClickedSectionId(sectionId);
      setTimeout(() => {
        setClickedSectionId(undefined);
      }, 1000);
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const selectedTab = React.useMemo(() => {
    if (
      featureDealInView &&
      (clickedSectionId ? clickedSectionId === "#feature-deals" : true)
    ) {
      return 0;
    }
    if (
      hotelDealsInView &&
      (clickedSectionId ? clickedSectionId === "#hotel-deals" : true)
    ) {
      return 1;
    }
    if (
      carDealsInView &&
      (clickedSectionId ? clickedSectionId === "#car-deals" : true)
    ) {
      return 3;
    }
    if (faqInView && (clickedSectionId ? clickedSectionId === "#faq" : true)) {
      return 4;
    }
    if (
      flightDealsInView &&
      (clickedSectionId ? clickedSectionId === "#flight-deals" : true)
    ) {
      return 2;
    }

    return undefined;
  }, [
    featureDealInView,
    hotelDealsInView,
    flightDealsInView,
    carDealsInView,
    faqInView,
  ]);

  const selectedAccountCurrency =
    selectedRewardsAccount?.rewardsBalance.currencyDescription ??
    selectedRewardsAccount?.rewardsBalance.currency;

  useEffect(() => {
    const toggleShowToTopButton = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 500) {
        setVisible(true);
      } else if (scrolled <= 500) {
        setVisible(false);
      }
    };

    fetchTravelWalletDetails();
    window.addEventListener("scroll", toggleShowToTopButton);
    return () => {
      window.removeEventListener("scroll", toggleShowToTopButton);
    };
  }, []);

  const headerBreadCrumbLinks = [
    {
      onClick: () => {
        history.push(BASE_PATH_HOME);
      },
      label: "Search",
    },
    {
      label: textConstants.PAGE_LABEL,
    },
  ];

  const getCTARoute = (
    funnel: Funnel,
    offerLocation?: string,
    isMobile?: boolean,
    description?: string
  ) => {
    switch (funnel) {
      case Funnel.Air:
        return isMobile
          ? `${PATH_FLIGHTS_SEARCH}?entryType=${FlightEntryTypeEnum.TRAVEL_SALE_LANDING}`
          : PATH_FLIGHTS;
      case Funnel.Lodging:
        if (description && description?.includes("Premier Collection")) {
          return PATH_PREMIUM_STAYS;
        }
        if (offerLocation) {
          return `${PATH_HOTEL_AVAILABILITY}${transformToStringifiedHotelAvailabilityQuery(
            offerLocation,
            HotelEntryTypeEnum.TRAVEL_SALE_LANDING
          )}`;
        }
        return PATH_HOTELS;
      case Funnel.Ground:
        return isMobile
          ? `${PATH_CARS_SEARCH}?entryType=${CarEntryTypeEnum.TRAVEL_SALE_LANDING}`
          : PATH_CARS;
      default:
        return PATH_HOTELS;
    }
  };

  // TODO: Possibly refactor this logic in homepage
  const featureDealsOffers = React.useMemo(() => {
    const applicableOffers = {};
    config.travelSaleFeaturedDestinations.forEach((destination) => {
      applicableOffers[destination] = Array<ApplicableOffers>();
    });

    filteredTravelOffers
      .filter(
        (offer) =>
          config.travelSaleFeaturedDestinations.includes(
            offer.descriptions[2]?.trim()
          ) &&
          offer.funnels[0] !== Funnel.Air &&
          !config.travelSaleSoldOutHotelDestinations.includes(
            offer.descriptions[2]
          )
      )
      .forEach((offer) => {
        const destination = offer.descriptions[2]?.trim();
        applicableOffers[destination].unshift({
          offer: offer,
          onClickCTA: () => {
            history.push(
              getCTARoute(
                offer.funnels[0],
                offer.descriptions[2],
                matchesMobile,
                offer.descriptions[0]
              )
            ),
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 400);

            const properties: ClickedSaleOfferCTATrackingProperties = {
              location_brand: offer.descriptions[2],
              funnel: getFunnelTrackingName(offer.funnels[0]),
            };
            trackEvent({
              eventName: CLICKED_SALE_OFFER_CTA,
              properties,
            });
          },
        });
      });

    const groupedOffers = Array<GroupedOffer>();
    Object.keys(applicableOffers).forEach((key) => {
      if (applicableOffers[key].length > 0) {
        const imageId = applicableOffers[key][0]?.offer?.imageId // we know this is non-empty
        const imagePath = imageId ?? key
        groupedOffers.push({
          image: `${window.location.origin}/email-assets/offer-images/${imagePath}/750x300.jpg`,
          applicableOffers: applicableOffers[key],
        });
      }
    });

    return groupedOffers.sort((a, b) =>
      sortDestinations(
        a.applicableOffers[0].offer.descriptions[2],
        b.applicableOffers[0].offer.descriptions[2]
      )
    );
  }, [filteredTravelOffers]);

  const sortedFlightOffers = React.useMemo(
    () =>
      offersByFunnel.Air.sort((a, b) => {
        if (isOfferSoldOut(a) && !isOfferSoldOut(b)) return 1;
        if (!isOfferSoldOut(a) && isOfferSoldOut(b)) return -1;

        return 0;
      }),
    [offersByFunnel.Air]
  );

  const hotelOffersWithSoldOut = React.useMemo(() => {
    const soldOutOffers: TravelWalletOffer[] =
      config.travelSaleSoldOutHotelDestinations
        .filter(
          (soldOutDestination) =>
            // filter out sold out destinations that already exist in api payload (for whatever reason)
            !offersByFunnel.Lodging.some(
              (offer) => offer.descriptions[2] === soldOutDestination
            )
        )
        .map((soldOutDestination) => {
          // construct dummy offer
          return {
            saleEventStartOn: "2023-10-10T04:00:00.000Z",
            shopPageBanner: "",
            funnels: [Funnel.Lodging],
            amount: {
              amount: 0,
              currency: "USD",
            },
            expiresOn: "2024-01-01T03:59:59.999Z",
            availabilityPageBanner: "",
            id: `sold-out-offer-${soldOutDestination}`,
            termsAndConditions: "",
            title: "",
            landingPageBanner: "",
            descriptions: ["", "", soldOutDestination],
          };
        });

    const sortedHotelOffers = [
      ...offersByFunnel.Lodging,
      ...soldOutOffers,
    ].sort((a, b) => {
      if (
        isOfferSoldOut(a, config.travelSaleSoldOutHotelDestinations) &&
        !isOfferSoldOut(b, config.travelSaleSoldOutHotelDestinations)
      )
        return 1;
      if (
        !isOfferSoldOut(a, config.travelSaleSoldOutHotelDestinations) &&
        isOfferSoldOut(b, config.travelSaleSoldOutHotelDestinations)
      )
        return -1;

      return 0;
    });

    return sortedHotelOffers;
  }, [offersByFunnel.Lodging]);

  const SectionHeader = (props: {
    title: string;
    dealsRemaining: string;
    titleHtml: boolean;
    iconName: string;
  }) => {
    return (
      <Box className="section-header">
        <Icon className="section-title-icon" name={props.iconName} />
        <Box className="section-title-container">
          {props.titleHtml ? (
            <Typography
              variant="h2"
              className={clsx("section-title", { mobile: matchesMobile })}
              dangerouslySetInnerHTML={{
                __html: matchesDesktop
                  ? `${props.title} ${props.dealsRemaining}`
                  : props.title,
              }}
            />
          ) : (
            <Typography
              className={clsx("section-title", { mobile: matchesMobile })}
              variant="h2"
            >
              {matchesDesktop
                ? `${props.title} ${props.dealsRemaining}`
                : props.title}
            </Typography>
          )}
          {matchesMobile && (
            <Typography className="section-title-caption" variant="body1">
              {props.dealsRemaining}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  const TravelSaleOfferGrid = (props: {
    travelWalletOffers: TravelWalletOffer[];
    funnel: Funnel;
  }) => {
    const { travelWalletOffers, funnel } = props;
    return (
      <div
        className={clsx("travel-sale-offers-grid-container", {
          mobile: matchesMobile,
          "has-filters": hasFilters,
        })}
      >
        {travelWalletOffers.map((offer) => {
          const imagePath = offer.imageId ?? offer.descriptions[2]
          const imageUrl = `${window.location.origin}/email-assets/offer-images/${imagePath}/560x690.jpg`;
          const showSoldOut = isOfferSoldOut(
            offer,
            config.travelSaleSoldOutHotelDestinations
          );

          return (
            <TravelOfferSaleCard
              applicableOffers={[
                {
                  offer: offer,
                  soldOut: showSoldOut,
                  onClickCTA: () => {
                    history.push(
                      getCTARoute(
                        funnel,
                        offer.descriptions[2],
                        matchesMobile,
                        offer.descriptions[0]
                      )
                    );

                    const properties: ClickedSaleOfferCTATrackingProperties = {
                      location_brand: offer.descriptions[2],
                      funnel: getFunnelTrackingName(offer.funnels[0]),
                    };
                    trackEvent({
                      eventName: CLICKED_SALE_OFFER_CTA,
                      properties,
                    });
                  },
                },
              ]}
              image={imageUrl}
              active={isActiveState}
              isMobile={matchesMobile}
              onOpenModal={(selectedOffer) =>
                trackEvent({
                  eventName: VIEWED_SALE_OFFER_MODAL,
                  properties: {
                    funnel: getFunnelTrackingName(selectedOffer.funnels[0]),
                    location_brand: selectedOffer.descriptions[2],
                  } as ViewedSaleOfferModalTrackingProperties,
                })
              }
            />
          );
        })}
      </div>
    );
  };

  const FAQContent = [
    {
      title: "What is the Capital One Travel sale and how does it work?",
      body: (
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: `The Capital One Travel sale is a limited-time event, with exclusive discounts for Capital One cardholders. To use a discount, simply search for a flight or hotel in one of the sale destinations, or a rental car from Avis, Budget, or Fox. You’ll see the offer details while you shop, while supplies last, and the discount will be automatically applied to your total at checkout. The travel sale offers are as follows:<ul><li>Offers for flights are a fixed dollar amount off the total flight cost. <ul><li>For example, if you select a $500 flight to Paris, including taxes and fees, using the $100 off flights offer, your flight cost would be $400. </li></ul></li><li>Offers for hotels are a percentage off the total booking price, up to a fixed dollar amount. <ul><li>For example, if you select a hotel in Rome for $300 including taxes and fees with a 20% off offer (up to $200), then your offer value will be $60 (20% of $300). If you select a hotel in Rome for $2000  including taxes and fees with the same offer, then your offer value would be $200 (the maximum offer amount).</li></ul></li><li>Offers for rental cars include bookings with Avis, Budget and Fox.</li></ul>`,
          }}
        ></Typography>
      ),
    },
    {
      title: "How long is the Capital One Travel sale?",
      body: "Details for each offer, including expiration dates, can be found by clicking the “i” information icon next to each offer. Most offers are available until 10/17 or while supplies last, and will be available on a first-come, first-serve basis. If you no longer see an offer, it has likely expired or sold out.",
    },
    {
      title: "Can I use multiple offers during the Capital One Travel sale?",
      body: "Yes, you can redeem multiple offers, while supplies last. So feel free to book that flight to Aspen and a hotel in Paris! However, each specific offer (e.g.,  $100 off a flight to Paris) can only be used once per customer.",
    },
    {
      title:
        "If I use a travel offer, will I still earn rewards for that booking?",
      body: "Yes, you will earn rewards on every booking you make through Capital One Travel with an eligible Capital One credit card.",
    },
    {
      title:
        "If I use a travel offer, can I also use my rewards to pay for that booking?",
      body: "Yes, after the travel offer has been applied to your total, you can use any combination of travel credits, rewards or an eligible Capital One credit card to cover any remaining booking cost.",
    },
    {
      title: "If I use a travel offer, can I still request a price match?",
      body: "Yes! We offer Capital One Travel’s price match guarantee on eligible flight, hotel and rental car purchases made through Capital One Travel if you find a qualifying, cheaper itinerary elsewhere and let us know within 24 hours of booking. If applicable, Capital One will issue you a travel credit for the difference. Perks and qualifying activities vary by location and are subject to availability. Price matching before and after you book may not be available on all reservations. Terms apply. See terms and conditions.",
    },
  ];

  const FAQ = () => {
    return (
      <Box className="faq-container">
        <Typography variant="h2" className="faq-title">
          Have questions? We’ve got answers.
        </Typography>
        <AccordionCollection
          accordionContents={FAQContent}
          expandIcon={<Icon name="plus" />}
          collapseIcon={<Icon name="minus" />}
          className="travel-sale-faq-accordion"
        />
      </Box>
    );
  };

  const renderOptions = () => {
    return Object.keys(config.filters).map((filter) => (
      <FormControlLabel
        control={
          <Radio
            checked={selectedFilter === filter}
            tabIndex={-1}
            className="checkbox"
            onChange={(e) => {
              e.target.checked ? selectFilter(filter) : clearSelectedFilters();
            }}
            value={filter}
          />
        }
        label={textConstants.FILTER_NAMES[filter]}
      />
    ));
  };

  const renderFormControl = () => (
    <FormControl
      className="location-type-selection-form-control"
      component="fieldset"
    >
      <FormGroup>{renderOptions()}</FormGroup>
    </FormControl>
  );

  const renderDropdownContent = () => {
    return (
      <Box className={"location-type-selection-root"}>
        <Box className="location-type-selection-container">
          {renderFormControl()}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      className={clsx("travel-sale-landing-root", { mobile: matchesMobile })}
    >
      <SecondaryHeader
        title={textConstants.HEADER_TITLE_SALE}
        subtitle={textConstants.HEADER_SUBTITLE_SALE}
        breadCrumbLinks={headerBreadCrumbLinks}
        className="travel-sale-header"
        rightContent={
          travelSaleVariant === TRAVEL_SALE_LEAD_UP ? (
            <TravelSalesEventBanner
              variant={"with-timer"}
              subtitle={textConstants.SALES_EVENT_LEADUP_SUBTITLE}
            />
          ) : undefined
        }
      />
      {matchesDesktop && (
        <Box className="nav-container">
          <PageTabNavigations
            navItems={[
              {
                label: textConstants.FEATURED_DEALS(featureDealsOffers.length),
                iconName: "feature-deal",
                onClick: () => scrollToSection("feature-deals"),
              },
              {
                label: textConstants.HOTEL_DEALS(
                  offersByFunnel.Lodging.filter(
                    (offer) =>
                      !isOfferSoldOut(
                        offer,
                        config.travelSaleSoldOutHotelDestinations
                      )
                  ).length
                ),
                iconName: "hotel-funnel-icon",
                onClick: () => scrollToSection("hotel-deals"),
              },
              {
                label: textConstants.FLIGHT_DEALS(
                  offersByFunnel.Air.filter(
                    (airOffer) => !isOfferSoldOut(airOffer)
                  ).length
                ),
                iconName: "flight-funnel-icon",
                onClick: () => scrollToSection("flight-deals"),
              },
              {
                label: textConstants.CAR_DEALS(offersByFunnel.Ground.length),
                iconName: "car-funnel-icon",
                onClick: () => scrollToSection("car-deals"),
              },
              {
                label: textConstants.FAQ,
                iconName: "question-mark-support-icon",
                onClick: () => scrollToSection("faq"),
              },
            ]}
            selectedTab={selectedTab}
            ariaLabel="travel sale tabs"
          />
          <Box className="filter-container">
            <Typography className="filter-label" variant="body1">
              {textConstants.FILTER_BY}
            </Typography>
            <GenericDropdown
              buttonClassName={clsx("location-type-dropdown", {
                "has-value": selectedFilter,
              })}
              dropdownLabel={
                <>
                  <strong>
                    {textConstants.LOCATION_TYPE}
                    {selectedFilter ? ": " : ""}
                  </strong>
                  {selectedFilter ? filterLabel() : ""}
                </>
              }
              dropdownContent={renderDropdownContent()}
              dropdownIcon={
                selectedFilter ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      clearSelectedFilters();
                    }}
                  >
                    <Icon name={IconName.XCircle} />
                  </div>
                ) : undefined
              }
            />
          </Box>
        </Box>
      )}
      {matchesMobile && (
        <Box className={clsx("filter-container", "mobile")}>
          <Typography className="filter-label" variant="body1">
            {textConstants.FILTER_BY}
          </Typography>
          <GenericDropdown
            buttonClassName={clsx("location-type-dropdown", {
              "has-value": selectedFilter,
            })}
            dropdownLabel={
              <>
                <strong>
                  {textConstants.LOCATION_TYPE}
                  {selectedFilter ? ": " : ""}
                </strong>
                {selectedFilter ? filterLabel() : ""}
              </>
            }
            dropdownContent={renderDropdownContent()}
            dropdownIcon={
              selectedFilter ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    clearSelectedFilters();
                  }}
                >
                  <Icon name={IconName.XCircle} />
                </div>
              ) : undefined
            }
          />
        </Box>
      )}

      {matchesMobile ? (
        <MobileTravelSaleSearchControl />
      ) : (
        <TravelSaleSearchControl />
      )}
      {fetchTravelWalletDetailsCallState === CallState.InProcess ? (
        <Box className="loading-container">
          <B2BSpinner />
        </Box>
      ) : (
        <>
          {!hasFilters && (
            <div id="feature-deals" ref={featureDealRef}>
              <Box
                className={clsx(
                  "travel-sale-body-section",
                  "includes-carousel",
                  {
                    mobile: matchesMobile,
                  }
                )}
              >
                <SectionHeader
                  title={textConstants.FEATURED_DEAL_TITLE(matchesMobile)}
                  dealsRemaining={textConstants.DEALS_REMAINING(
                    featureDealsOffers.length,
                    config.travelSaleInitialOfferCounts.FEATURED,
                    true
                  )}
                  iconName={"feature-deal"}
                  titleHtml={false}
                />
                <TravelSaleCardCollection
                  groupedOffers={featureDealsOffers}
                  cardVariant={"featured"}
                  active={isActiveState}
                  isMobile={matchesMobile}
                  onOpenModal={(selectedOffer) =>
                    trackEvent({
                      eventName: VIEWED_SALE_OFFER_MODAL,
                      properties: {
                        funnel: getFunnelTrackingName(selectedOffer.funnels[0]),
                        location_brand: selectedOffer.descriptions[2],
                      } as ViewedSaleOfferModalTrackingProperties,
                    })
                  }
                />
              </Box>
            </div>
          )}
          {(hasFilters ? !!hotelOffersWithSoldOut.length : true) && (
            <div
              id="hotel-deals"
              className={clsx("grid-section", {
                mobile: matchesMobile,
                filtered: hasFilters,
                "has-search": !!searchLocation,
              })}
              ref={hotelDealsRef}
            >
              <Box
                className={clsx("travel-sale-body-section", {
                  mobile: matchesMobile,
                })}
              >
                <SectionHeader
                  title={textConstants.HOTEL_DEAL_TITLE(
                    selectedRewardsAccount?.earn.hotelsMultiplier,
                    selectedAccountCurrency,
                    matchesMobile
                  )}
                  dealsRemaining={textConstants.DEALS_REMAINING(
                    offersByFunnel.Lodging.filter(
                      (offer) =>
                        !isOfferSoldOut(
                          offer,
                          config.travelSaleSoldOutHotelDestinations
                        )
                    ).length,
                    config.travelSaleInitialOfferCounts.HOTELS,
                    !hasFilters
                  )}
                  iconName={"hotel-funnel-icon"}
                  titleHtml={true}
                />
                <TravelSaleOfferGrid
                  travelWalletOffers={
                    searchLocation
                      ? offersByFunnel.Lodging
                      : hotelOffersWithSoldOut
                  }
                  funnel={Funnel.Lodging}
                />
              </Box>
            </div>
          )}
          {(hasFilters ? !!sortedFlightOffers.length : true) && (
            <div
              id="flight-deals"
              className={clsx("grid-section", {
                mobile: matchesMobile,
                filtered: hasFilters,
                "has-search": !!searchLocation,
              })}
              ref={flightDealsRef}
            >
              <Box
                className={clsx("travel-sale-body-section", {
                  mobile: matchesMobile,
                })}
              >
                <SectionHeader
                  title={textConstants.FLIGHT_DEAL_TITLE(
                    selectedRewardsAccount?.earn.flightsMultiplier,
                    selectedAccountCurrency,
                    matchesMobile
                  )}
                  dealsRemaining={textConstants.DEALS_REMAINING(
                    sortedFlightOffers.filter(
                      (airOffer) => !isOfferSoldOut(airOffer)
                    ).length,
                    config.travelSaleInitialOfferCounts.FLIGHTS,
                    !hasFilters
                  )}
                  iconName={"flight-funnel-icon"}
                  titleHtml={!matchesMobile}
                />
                <TravelSaleOfferGrid
                  travelWalletOffers={sortedFlightOffers}
                  funnel={Funnel.Air}
                />
              </Box>
            </div>
          )}
          {(hasFilters ? !!offersByFunnel.Ground.length : true) && (
            <div
              id="car-deals"
              className={clsx("grid-section", {
                mobile: matchesMobile,
                filtered: hasFilters,
                "has-search": !!searchLocation,
              })}
              ref={carDealsRef}
            >
              <Box
                className={clsx("travel-sale-body-section", {
                  mobile: matchesMobile,
                })}
              >
                <SectionHeader
                  title={textConstants.CAR_DEAL_TITLE(
                    selectedRewardsAccount?.earn.carsMultiplier,
                    selectedAccountCurrency,
                    matchesMobile
                  )}
                  dealsRemaining={textConstants.DEALS_REMAINING(
                    offersByFunnel.Ground.length,
                    config.travelSaleInitialOfferCounts.CARS,
                    !hasFilters
                  )}
                  iconName={"car-funnel-icon"}
                  titleHtml={!matchesMobile}
                />
                <TravelSaleOfferGrid
                  travelWalletOffers={offersByFunnel.Ground}
                  funnel={Funnel.Ground}
                />
              </Box>
            </div>
          )}
          {showToTopButton && (
            <ButtonWrap
              className="back-to-top-button"
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              }
            >
              <Box>
                <Icon name={IconName.BackToTopIcon} />
              </Box>
            </ButtonWrap>
          )}
          <div id="faq" ref={faqRef}>
            <FAQ />
          </div>
        </>
      )}
    </Box>
  );
};
